
import { defineComponent, computed, PropType } from '@nuxtjs/composition-api'
import BalloonDropdown, { POSITIONS } from '../../BalloonDropdown'

export const TOOLTIP_POSITIONS = POSITIONS
export const TOOLTIP_TRIGGER = ['click', 'hover'] as const

export default defineComponent({
  components: { BalloonDropdown },
  props: {
    position: {
      type: String as PropType<(typeof TOOLTIP_POSITIONS)[number]>,
      default: 'bottom-center',
      validator: (v: (typeof TOOLTIP_POSITIONS)[number]) => TOOLTIP_POSITIONS.includes(v)
    },
    trigger: {
      type: String as PropType<(typeof TOOLTIP_TRIGGER)[number]>,
      default: 'hover',
      validator: (v: (typeof TOOLTIP_TRIGGER)[number]) => TOOLTIP_TRIGGER.includes(v)
    },
    width: {
      type: String
    },
    label: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const isCentering = computed(
      () =>
        // hover時のみセンタリングがずれるので解消
        ['bottom-center', 'top-center'].includes(props.position) && props.trigger === 'hover'
    )
    return { isCentering }
  }
})
