<template>
  <div>
    <HeadBanner
      :service-class="serviceDetail.serviceDetail.serviceClass"
      :parent-category-id="parentCategoryId"
      page-type="serviceDetail"
    />
    <div class="c-contentHeader">
      <SearchBreadcrumb
        :bread="bread"
        :is-loading="isLoading"
      />
      <div
        class="c-notActive"
        v-if="
          isNotServiceForForeigners ||
          $util.isBeforePeriodDate(user.lastLoginedAt, 180) ||
          $util.isBeforePeriodDate(user.lastLoginedAt, 30)
        "
      >
        <CoconalaIcon
          name="exclamation-circle"
          class="c-notActive_icon"
        />
        <template v-if="isNotServiceForForeigners">
          {{ $t('service_detail.alert.onlyJapan') }}
        </template>
        <template v-else-if="$util.isBeforePeriodDate(user.lastLoginedAt, 180)">
          {{ $t('service_detail.alert.halfYearLogin') }}
        </template>
        <template v-else-if="$util.isBeforePeriodDate(user.lastLoginedAt, 30)">
          {{ $t('service_detail.alert.oneMonthLogin') }}
        </template>
      </div>
      <div
        v-if="getIsOnsiteByCategoryId(parentCategoryId)"
        class="c-onsiteInfo"
      >
        <CoconalaIcon
          name="info-circle"
          class="c-onsiteInfo_icon"
        />
        {{ $t('service_detail.alert.onsite') }}
      </div>
    </div>

    <div class="c-detailPage">
      <div
        class="c-columns"
        :class="{ 'c-columns-loading': isLoading }"
      >
        <div class="c-columns_main">
          <div class="c-header">
            <ServiceDetailTitle
              :service-detail="serviceDetail"
              :service-detail-ratings="serviceDetailRatings"
              :scroll-to-offset="scrollToOffset"
            />
          </div>
          <div
            class="c-serviceDetailImagesSpText"
            v-if="isServiceDetailImagesSpText"
          >
            <ServiceDetailImagesSp
              :service-name="serviceDetail.serviceDetail.name"
              :service-image-list="serviceDetail.serviceDetail.serviceImagesList"
              :service-movie-list="serviceDetail.serviceDetail.serviceMoviesList"
              :movie-priority-flag="serviceDetail.serviceDetail.moviePriorityFlag"
              :is-loading="isLoading"
            />
          </div>
          <ServiceDetailContents
            :position="position"
            :scroll-to-offset="scrollToOffset"
            :service-detail="serviceDetail"
            :total-price="totalPrice"
            :url-dm="urlDm"
            :url-customize="urlCustomize"
            :is-customize="isCustomize"
            :is-service-reservation="isServiceReservation"
            :is-blockee="user.userRelation.blockeeFlag"
            :is-my-service="isMyService"
            :set-reservation="setReservation"
            :options="options"
            :is-loading="isLoading"
            :is-parent-category-divination-phone="isParentCategoryDivinationPhone"
            @changeOption="setOptions($event)"
            @emitReservation="setReservation = true"
          />
        </div>

        <div class="c-columns_side">
          <ServiceDetailPurchase
            :service-detail="serviceDetail"
            :parent-category-id="parentCategoryId"
            :total-price="totalPrice"
            :url-customize="urlCustomize"
            :is-customize="isCustomize"
            :is-service-reservation="isServiceReservation"
            :set-reservation="setReservation"
            :options="options"
            :is-my-service="isMyService"
            @changeOption="setOptions($event)"
            @emitReservation="setReservation = true"
            @show-purchase-select-drawer="showPurchaseSelectDrawer"
          />
          <ClientOnly>
            <ServiceDetailShareMyService
              :is-monochromatic="false"
              :title="metaTitle"
              :path="$route.path"
              v-if="isPC && isMyService"
              @showModal="showServiceIntroduction"
            />
          </ClientOnly>
          <ServiceDetailProvider
            :service-detail="serviceDetail"
            :is-my-service="isMyService"
            :user="user"
            :url-dm="urlDm"
          />
          <div
            class="c-share"
            v-if="isPC"
          >
            <ClientOnly>
              <ShareLinks
                class="c-share_social"
                :is-monochromatic="false"
                :title="metaTitle"
                :path="$route.path"
              />
            </ClientOnly>
            <div
              v-if="!privateAccountInfo.isMzhc"
              class="c-blogButton"
            >
              <!-- prettier-ignore -->
              <CButtonV2
                size="s"
                color="thirdly"
                width="135px"
                @click="showServiceIntroduction"
              >{{ $t('service_detail.blog_advertise') }}</CButtonV2>
            </div>
            <ClientOnly>
              <ServiceIntroductionModal
                :active="isShowServiceIntroduction"
                :option="serviceIntroductionOption"
                @close="closeServiceIntroduction"
              />
            </ClientOnly>
          </div>
          <ClientOnly>
            <div v-if="isPC && isLoggedIn && !isMyService">
              <ServiceViolation @violation="showViolationModal" />
            </div>
          </ClientOnly>
        </div>
      </div>

      <div class="c-footer">
        <ServiceDetailFooter
          :position="position"
          :scroll-to-offset="scrollToOffset"
          :service-detail="serviceDetail"
          :service-detail-ratings="serviceDetailRatings"
          :rating-indicators="ratingIndicators"
          :is-my-service="isMyService"
          :is-loading="isLoading"
          :meta-title="metaTitle"
          @violation="showViolationModal"
        />
      </div>
    </div>
    <ClientOnly>
      <ViolationModal
        :show-violation-modal="isActiveViolationModal"
        :service-id="serviceId"
        @closeViolationModal="closeViolationModal"
      />
      <BottomFixedBar v-if="isPC && !isLoggedIn" />
      <GlobalPopup
        v-if="isLoggedIn"
        @completed-get-popup="onCompletedGetGlobalPopup"
      />
      <UserAttributesSelectModalWrapper
        v-if="showUserAttributesSelectModalWrapper"
        @close="showUserAttributesSelectModalWrapper = false"
      />
      <PurchaseSelectDrawer
        :is-active="isShowPurchaseSelectDrawer"
        :options="options"
        :total-price="totalPrice"
        @close="isShowPurchaseSelectDrawer = false"
      />
    </ClientOnly>
    <TheVueport breakpoint-type="vueportServiceDetail" />
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import Util from '~/assets/javascripts/util'
import servicesMixin from '~/mixins/pages/services-mixin'
import zendeskChatMixin from '~/mixins/zendesk-chat/private-mixin'

import SearchBreadcrumb from '~/components/molecules/SearchBreadcrumb'
import HeadBanner from '~/components/organisms/HeadBanner'
import ServiceDetailContents from '~/components/organisms/ServiceDetailContents'
import ServiceDetailFooter from '~/components/organisms/ServiceDetailFooter'
import ServiceDetailProvider from '~/components/organisms/ServiceDetailProvider'
import ServiceDetailPurchase from '~/components/organisms/ServiceDetailPurchase'
import ServiceDetailTitle from '~/components/organisms/ServiceDetailTitle'
import TheVueport from '~/components/organisms/TheVueport'

const CATEGORY_DIVINATION = {
  id: 3,
  name: '占い'
}
const FOOTER_BOTTOM_TB = '100px'
const FOOTER_BOTTOM_SP = '100px'

const SERVICE_ST_OPEN = 1
const SERVICE_ST_ARCHIVE = 2

const RECENTLY_VIEWED_COOKIE_KEY = 'CakeCookie[service_history_ids]'
const RECENTLY_VIEWED_COOKIE_KEY_V2 = 'service_history'
const RECENTLY_VIEWED_SERVICES_MAX = 40
const RECENTLY_VIEWED_SERVICES_EXPIRE_DAY = 30

export default {
  name: 'serviceDetail',
  components: {
    SearchBreadcrumb,
    HeadBanner,
    ServiceDetailContents,
    ServiceDetailFooter,
    ServiceDetailProvider,
    ServiceDetailPurchase,
    ServiceDetailTitle,
    TheVueport,
    BottomFixedBar: () => import('~/components/organisms/BottomFixedBar'),
    ServiceViolation: () => import('~/components/molecules/ServiceViolation'),
    GlobalPopup: () => import('~/components/organisms/GlobalPopup'),
    ServiceDetailImagesSp: () => import('~/components/organisms/ServiceDetailImagesSp'),
    ServiceDetailShareMyService: () => import('~/components/organisms/ServiceDetailShareMyService'),
    ServiceIntroductionModal: () => import('~/components/organisms/ServiceIntroductionModal'),
    ShareLinks: () => import('~/components/organisms/ShareLinks'),
    ViolationModal: () => import('~/components/organisms/ViolationModal'),
    UserAttributesSelectModalWrapper: () =>
      import('~/components/organisms/UserAttributesSelectModalWrapper'),
    PurchaseSelectDrawer: () => import('../components/PurchaseSelectDrawer')
  },
  mixins: [servicesMixin, zendeskChatMixin],
  middleware: [
    'redirectToContinueIfBusinessPreview',
    async function ({ store, params, route, error }) {
      const { fullPath, path } = route
      const sprKey = route.query.spr_key
      const detailJson = JSON.stringify({
        view_id: store.state.big_query.viewId
      })
      const promises = [
        store.dispatch('pages/services/serviceDetail/serviceRequests', {
          params,
          fullPath,
          sprKey,
          detailJson
        }),
        store.dispatch('pages/services/serviceDetailRatings/getRatingComments', {
          params
        }),
        store.dispatch('pages/services/serviceDetailRatings/getRatingSummary', {
          params
        })
      ]
      try {
        await Promise.all(promises)
        // serviceRequestsで取得してきたproviderIdを利用している
        await store.dispatch('pages/users/user/fetchUser', {
          userId: store.state.pages.services.serviceDetail.serviceDetail.providerId,
          options: {
            userJob: true,
            skill: true,
            userSpecialty: true,
            license: true,
            award: true
          },
          userAction: 'view_service_profile',
          detailJson: JSON.stringify({
            url: path
          })
        })
      } catch (err) {
        const statusCode = Util.grpcCodeToHttpStatusCode(err.grpcCode)
        return error({
          statusCode,
          message: '',
          grpcCode: err.grpcCode
        })
      }
    }
  ],
  head() {
    return this.head
  },
  provide() {
    return {
      $serviceOverallRatingCountReactive: () => this.overallRatingCount,
      impressionLogRatingServiceDetail: this.impressionLogRatingServiceDetail
    }
  },
  data() {
    return {
      position: 0,
      isLoading: false,
      bread: {
        type: 'serviceDetail',
        breadcrumb: []
      },
      options: [],
      optionsPrice: 0,
      setReservation: false,
      isShowServiceIntroduction: false,
      isNotServiceForForeigners: false,
      showUserAttributesSelectModalWrapper: false,
      isShowPurchaseSelectDrawer: false
    }
  },
  created() {
    this.generateBread()
    this.judgeServiceForForeigners()
  },
  mounted() {
    document.onscroll = e => {
      this.position = document.documentElement.scrollTop || document.body.scrollTop
    }
    this.updateFooterBottomTb(FOOTER_BOTTOM_TB)
    this.updateFooterBottomSp(FOOTER_BOTTOM_SP)

    this.setDataLayer()

    this.setBrowsingHistory()
    this.setBrowsingHistoryV2()
  },
  watch: {
    options(v) {
      let optionsPrice = 0
      v.forEach(optionId => {
        const price = this.serviceDetail.serviceDetail.optionsList.find(
          o => o.id === optionId
        ).priceWot
        optionsPrice = optionsPrice + price
      })
      this.optionsPrice = optionsPrice
    },
    isActiveViolationModal(v) {
      if (!this.isPC) {
        this.$util.hideZendeskWebWidgetWhileFlag(v)
      }
    },
    isShowPurchaseSelectDrawer(v) {
      this.$util.hideZendeskWebWidgetWhileFlag(v)
    }
  },
  computed: {
    ...mapState('master', ['masterCategories']),
    ...mapState('pages/services', ['serviceDetail', 'serviceDetailRatings', 'providerSummary']),
    ...mapState('pages/users', ['user']),
    ...mapState('constants', ['serviceClass', 'VIRTUAL_CATEGORY', 'CRITEO_PHONE_PRICE_AVG']),
    ...mapState('ui', ['isActiveViolationModal']),
    ...mapState('my/header', ['privateAccountInfo']),
    ...mapGetters('ui', ['isPC', 'isTB', 'isSP']),
    ...mapGetters('auth', ['isFromCoconalaApp', 'isLoggedIn']),
    ...mapGetters('master', ['getIsOnsiteByCategoryId']),
    /** @returns {import('vue-meta').MetaInfo} */
    head() {
      return {
        __dangerouslyDisableSanitizers: ['script'],
        script: [
          {
            hid: 'json-ld-product',
            type: 'application/ld+json',
            innerHTML: this.$util.escapeBreadcrumb(this.jsonLdProduct)
          },
          {
            hid: 'json-ld-breadcrumb',
            type: 'application/ld+json',
            innerHTML: this.$util.escapeBreadcrumb(this.jsonLdBreadcrumb)
          }
        ],
        link: [
          { rel: 'canonical', href: this.metaCanonical },
          { rel: 'alternate', href: this.metaAndroidAppLink }
        ],
        title: this.serviceDetail.serviceDetail.name,
        bodyAttrs: {
          class: ['-fixedLessEqualM']
        },
        meta: [
          {
            hid: 'description',
            name: 'description',
            content: this.metaDescription
          },
          {
            hid: 'Keywords',
            name: 'Keywords',
            content: this.metaKeywords
          },
          ...this.metaOgp
        ]
      }
    },
    jsonLdProduct() {
      const jsonLd = {
        '@context': 'http://schema.org/',
        '@type': 'Product',
        name: this.serviceDetail.serviceDetail.name,
        image: this.getMetaImage(this.serviceDetail.serviceDetail),
        description: this.generateDescription(this.serviceDetail.serviceDetail.head),
        offers: {
          '@type': 'Offer',
          priceCurrency: 'JPY',
          price: this.serviceDetail.serviceDetail.priceWot,
          availability: 'http://schema.org/OnlineOnly'
        }
      }

      if (this.overallRatingCount > 0) {
        jsonLd['aggregateRating'] = {
          '@type': 'AggregateRating',
          ratingValue: this.serviceDetailRatings.overallRating.indicator,
          reviewCount: this.overallRatingCount
        }
      }

      return jsonLd
    },
    jsonLdBreadcrumb() {
      const getBreadItem = param => {
        return {
          '@type': 'ListItem',
          position: param.position,
          item: {
            '@id': this.$coconala.url(param.path),
            name: param.name
          }
        }
      }
      const breadTop = getBreadItem({
        position: 1,
        path: '/',
        name: 'ココナラTOP'
      })
      const breads = this.bread.breadcrumb.map((item, index) => {
        return getBreadItem({
          position: index + 2,
          path: item.url,
          name: item.name
        })
      })

      return {
        '@context': 'http://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: [breadTop, ...breads]
      }
    },
    scrollToOffset() {
      if (this.isSP) {
        return -19
      } else if (this.isTB) {
        return 0
      } else {
        return -90
      }
    },
    currentCategoryId() {
      if (this.serviceDetail.serviceDetail.masterChildCategoryId) {
        return Number(this.serviceDetail.serviceDetail.masterChildCategoryId)
      }
      if (this.serviceDetail.serviceDetail.masterParentCategoryId) {
        return Number(this.serviceDetail.serviceDetail.masterParentCategoryId)
      }
      return null
    },
    parentCategoryId() {
      if (this.serviceDetail.serviceDetail.masterParentCategoryId) {
        return Number(this.serviceDetail.serviceDetail.masterParentCategoryId)
      }
      return null
    },
    providerId() {
      return this.serviceDetail.serviceDetail.providerId
    },
    serviceId() {
      return this.serviceDetail.serviceDetail.id
    },
    currentServiceClass() {
      return Number(this.serviceDetail.serviceDetail.serviceClass)
    },
    // ratingSummaryListがArrayになっているので変換する
    ratingIndicators() {
      if (!this.serviceDetailRatings.ratingSummaryList.length) {
        return {}
      }
      const indicators = {}
      this.serviceDetailRatings.ratingSummaryList.forEach(indicator => {
        const key = indicator.type
        const value = indicator
        indicators[key] = value
      })
      return indicators
    },
    overallRatingCount() {
      if (
        !this.serviceDetailRatings.overallRating ||
        !this.serviceDetailRatings.overallRating.ratingCount
      ) {
        return 0
      }
      return this.serviceDetailRatings.overallRating.ratingCount
    },
    // 占いカテゴリ且つ電話サービスの判定
    isCategoryDivinationPhone() {
      return (
        this.currentCategoryId === this.VIRTUAL_CATEGORY.DIVINATION_PHONE.CONDITION.CATEGORY_ID &&
        this.currentServiceClass === this.VIRTUAL_CATEGORY.DIVINATION_PHONE.CONDITION.SERVICE_CLASS
      )
    },
    // 親カテが占いカテゴリ且つ電話サービスの判定
    isParentCategoryDivinationPhone() {
      return (
        this.parentCategoryId === this.VIRTUAL_CATEGORY.DIVINATION_PHONE.CONDITION.CATEGORY_ID &&
        this.currentServiceClass === this.VIRTUAL_CATEGORY.DIVINATION_PHONE.CONDITION.SERVICE_CLASS
      )
    },
    // ServiceDetailImagesSpTextを表示する条件（デバイス判定は除く）
    isServiceDetailImagesSpText() {
      return (
        this.serviceDetail.serviceDetail.serviceClass === this.serviceClass.text &&
        (this.serviceDetail.serviceDetail.serviceImagesList.length ||
          this.serviceDetail.serviceDetail.serviceMoviesList.length)
      )
    },
    // 見積り・カスタマイズが可能かどうか
    isCustomize() {
      return (
        !this.user.userRelation.blockeeFlag &&
        !this.serviceDetail.serviceDetail.stopFg &&
        this.serviceDetail.serviceDetail.opened === SERVICE_ST_OPEN &&
        this.serviceDetail.serviceDetail.customizeFlag
      )
    },
    // 再開通知が可能かどうか
    isServiceReservation() {
      if (
        !this.isLoggedIn &&
        this.serviceDetail.serviceDetail.stopFg &&
        this.serviceDetail.serviceDetail.opened !== SERVICE_ST_ARCHIVE
      ) {
        return true
      }
      if (
        this.isLoggedIn &&
        !this.user.userRelation.blockeeFlag &&
        !this.isMyService &&
        this.serviceDetail.serviceDetail.stopFg &&
        this.serviceDetail.serviceDetail.opened !== SERVICE_ST_ARCHIVE
      ) {
        return true
      }
      return false
    },
    // 自分のサービスかどうか
    isMyService() {
      return this.serviceDetail.serviceDetail.providerId === this.serviceDetail.buyer.userId
    },
    isPhoneService() {
      return this.serviceDetail.serviceDetail.serviceClass === this.serviceClass.phone
    },

    // 有料オプションを含めた金額
    totalPrice() {
      return Number(this.serviceDetail.serviceDetail.priceWot) + this.optionsPrice
    },
    urlDm() {
      const basePath = '/mypage/direct_message_create/'
      const path = `${basePath}${this.providerId}`

      return this.$coconala.url(path, { sid: this.serviceId })
    },
    urlCustomize() {
      const basePath = '/customize/requests/add/'
      const path = `${basePath}${this.providerId}/${this.serviceId}`

      return this.$coconala.url(path)
    },
    serviceIntroductionOption() {
      const hasRated = this.serviceDetailRatings.ratingCommentsList.length > 0
      const userId = this.serviceDetail.buyer.userId === 0 ? '' : this.serviceDetail.buyer.userId
      return {
        serviceName: this.serviceDetail.serviceDetail.name,
        serviceId: this.serviceDetail.serviceDetail.id,
        userId,
        isLoggedIn: this.isLoggedIn,
        hasRated
      }
    },
    metaTitle() {
      return this.getMetaTitle()
    },
    metaDescription() {
      return this.generateDescription(this.serviceDetail.serviceDetail.head, 160)
    },
    metaKeywords() {
      const catchphrase = this.serviceDetail.serviceDetail.catchphrase
      const overview = this.serviceDetail.serviceDetail.overview
      const keyword = (() => {
        if (overview && catchphrase) {
          return `${overview} ${catchphrase},`
        }
        if (overview && !catchphrase) {
          return `${overview},`
        }
        if (!overview && catchphrase) {
          return `${catchphrase},`
        }
        return ''
      })()
      // prettier-ignore
      return `${keyword}${this.serviceDetail.serviceDetail.childCategoryName},マーケットプレイス,ココナラ,coconala,ここなら`
    },
    metaCanonical() {
      return this.$coconala.absoluteUrl(this.$route.path)
    },
    metaOgp() {
      return [
        {
          hid: 'og:url',
          property: 'og:url',
          content: this.$coconala.url(this.$route.path)
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: this.getMetaTitle()
        },
        // TODO 後でnuxt.config.tsに移す
        {
          hid: 'og:locale',
          property: 'og:locale',
          content: 'ja_JP'
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: `${this.generateDescription(this.serviceDetail.serviceDetail.head, 80)}...`
        },
        this.metaOgImage
      ]
    },

    metaOgImage() {
      return {
        hid: 'og:image',
        property: 'og:image',
        content: this.getMetaImage(this.serviceDetail.serviceDetail, 'ogp')
      }
    },
    metaAndroidAppLink() {
      return `android-app://com.coconala.android.portal/coconala-android/items${this.$route.path}`
    },
    impressionLogRatingServiceDetail() {
      const getQuery = param => {
        return this.$route.query[param] || null
      }
      const ratingStars = {}
      this.serviceDetailRatings.starsList.forEach(ratingStar => {
        const key = ratingStar.star
        const value = ratingStar.count
        ratingStars[key] = value
      })
      return {
        view_id: this.$store.state.big_query.viewId,
        service_id: this.serviceDetail.serviceDetail.id,
        url: this.$route.path,
        impression_user_ids: [this.providerId],
        service_pr_uuid: getQuery('service_pr_uuid'),
        service_order: getQuery('service_order'),
        service_order_with_pr: getQuery('service_order_with_pr'),
        service_order_only_pr: getQuery('service_order_only_pr'),
        is_own_service: this.isMyService,
        overall_rating_indicator: Number(
          this.serviceDetail.serviceDetail.rating.indicator.toFixed(1)
        ),
        overall_rating_count: this.overallRatingCount,
        star_rating_5: ratingStars[5] || 0,
        star_rating_4: ratingStars[4] || 0,
        star_rating_3: ratingStars[3] || 0,
        star_rating_2: ratingStars[2] || 0,
        star_rating_1: ratingStars[1] || 0
      }
    }
  },
  methods: {
    ...mapActions('ui', [
      'updateFooterBottomTb',
      'updateFooterBottomSp',
      'showViolationModal',
      'closeViolationModal'
    ]),
    // 占いカテゴリかどうかの判定
    isCategoryDivination(id) {
      return id === CATEGORY_DIVINATION.id
    },
    generateBread() {
      const parentCategoryId = this.serviceDetail.serviceDetail.masterParentCategoryId
      const parentCategoryName = this.serviceDetail.serviceDetail.masterParentCategoryName
      const childCategoryId = this.serviceDetail.serviceDetail.masterChildCategoryId
      const childCategoryName = this.serviceDetail.serviceDetail.masterChildCategoryName
      const categoryTypeId = this.serviceDetail.serviceDetail.masterCategoryTypeId
      const categoryTypeName = this.serviceDetail.serviceDetail.masterCategoryTypeName
      const serviceId = this.serviceDetail.serviceDetail.id
      const serviceName = (() => {
        if (this.serviceDetail.serviceDetail.overview) {
          return `${this.serviceDetail.serviceDetail.overview}${this.serviceDetail.serviceDetail.catchphrase}`
        } else {
          return this.serviceDetail.serviceDetail.name
        }
      })()

      const parentCategoryData = this.masterCategories.find(
        masterCategory => masterCategory.id === parentCategoryId
      )

      const childCategoryData = parentCategoryData.subCategories.filter(category => {
        return category.id === childCategoryId
      })[0]

      const baseParentCategory = {
        id: parentCategoryId,
        url: `/categories/${parentCategoryId}`,
        isCurrent: false
      }

      const baseChildCategory = {
        id: childCategoryId,
        url: `/categories/${childCategoryId}`,
        isCurrent: false
      }

      const currentService = {
        id: 9999,
        url: `/services/${serviceId}`,
        isCurrent: true,
        name: serviceName
      }

      const _bread = []

      // 大カテ
      const parentCategory = (() => {
        // category_seos テーブルのレコード有
        if (parentCategoryData.textSeoName) {
          return {
            ...baseParentCategory,
            name: parentCategoryData.textSeoName
          }
        }
        // category_seos テーブルのレコードなし
        return {
          ...baseParentCategory,
          name: parentCategoryName
        }
      })()

      _bread.push(parentCategory)

      // テキストサービスのとき
      if (this.currentServiceClass === this.serviceClass.text) {
        // 小カテ
        if (childCategoryData) {
          const childCategory = (() => {
            // category_seos テーブルのレコード有
            if (childCategoryData.textSeoName) {
              return {
                ...baseChildCategory,
                name: childCategoryData.textSeoName
              }
            }
            // category_seos テーブルのレコード無し
            return {
              ...baseChildCategory,
              name: childCategoryName
            }
          })()
          _bread.push(childCategory)
        }
      } else {
        // 電話サービスの時
        const generatePhoneServiceUrl = url => {
          return `${url}?service_kind=${this.serviceClass.phone}`
        }

        // 中カテ
        const parentPhoneCategory = (() => {
          // category_seos テーブルのレコード有
          if (parentCategoryData.phoneSeoName) {
            const category = {
              ...baseParentCategory,
              name: parentCategoryData.phoneSeoName
            }
            category.url = generatePhoneServiceUrl(category.url)
            return category
          }

          // 大カテゴリが占いの時
          if (this.isCategoryDivination(this.parentCategoryId)) {
            return {
              id: this.VIRTUAL_CATEGORY.DIVINATION_PHONE.ID,
              url: `/categories/${this.VIRTUAL_CATEGORY.DIVINATION_PHONE.CONDITION.CATEGORY_ID}?service_kind=${this.VIRTUAL_CATEGORY.DIVINATION_PHONE.CONDITION.SERVICE_CLASS}`,
              isCurrent: false,
              name: this.VIRTUAL_CATEGORY.DIVINATION_PHONE.NAME
            }
          }

          // category_seos テーブルのレコードなし
          const category = {
            ...baseParentCategory,
            name: `${parentCategoryName}の電話相談`
          }
          category.url = generatePhoneServiceUrl(category.url)
          return category
        })()
        _bread.push(parentPhoneCategory)

        // 小カテ
        if (childCategoryData) {
          const childCategory = (() => {
            // category_seos テーブルのレコード有
            if (childCategoryData.phoneSeoName) {
              const category = {
                ...baseChildCategory,
                name: childCategoryData.phoneSeoName
              }
              category.url = generatePhoneServiceUrl(category.url)
              return category
            }
            // 大カテゴリが占いの時
            if (this.isCategoryDivination(this.currentCategoryId)) {
              const category = {
                ...baseChildCategory,
                name: `[電話占い]${childCategoryName}`
              }
              category.url = generatePhoneServiceUrl(category.url)
              return category
            }

            // category_seos テーブルのレコードなし
            const category = {
              ...baseChildCategory,
              name: `${childCategoryName}［電話］`
            }
            category.url = generatePhoneServiceUrl(category.url)
            return category
          })()
          _bread.push(childCategory)
        }
      }

      // カテゴリタイプがあったら追加
      if (categoryTypeId) {
        let urlCategoryId = childCategoryId || parentCategoryId
        const categoryType = {
          id: `categoryTypeId${categoryTypeId}`,
          name: categoryTypeName,
          url: `/categories/${urlCategoryId}/${categoryTypeId}`,
          isCurrent: false
        }

        // 電話サービスの時
        if (this.currentServiceClass === this.serviceClass.phone) {
          categoryType.url = `/categories/${urlCategoryId}/${categoryTypeId}?service_kind=${this.serviceClass.phone}`
        }

        _bread.push(categoryType)
      }

      _bread.push(currentService)

      this.bread.breadcrumb = _bread
    },
    judgeServiceForForeigners() {
      const isNotJapanese = this.$translate.isTranslatableTarget()
      this.isNotServiceForForeigners =
        isNotJapanese && (this.isPhoneService || this.serviceDetail.serviceDetail.videoType === 2)
    },
    generateDescription(text, count) {
      if (count) {
        return text.replace(/\r\n/g, '').substr(0, count)
      }
      return text.replace(/\r\n/g, '')
    },
    getMetaTitle() {
      const name = this.serviceDetail.serviceDetail.name
      const childCategoryName = this.serviceDetail.serviceDetail.masterChildCategoryName
      const parentCategoryName = this.serviceDetail.serviceDetail.masterParentCategoryName

      if (name) {
        const categoryName = childCategoryName ? childCategoryName : parentCategoryName
        return `${name} | ${categoryName} | ココナラ`
      }

      return 'ココナラ'
    },
    getServiceKind() {
      // ビデオチャットサービスの場合は2を返す
      if (this.serviceDetail.serviceDetail.videoType === 2) {
        return 2
      } else {
        return this.serviceDetail.serviceDetail.serviceClass
      }
    },
    // 移行期間終了後（トップレスポンシブリリース時）に消す
    setBrowsingHistory() {
      const serviceId = this.serviceDetail.serviceDetail.id
      const cookieValue = this.$cookies.get(RECENTLY_VIEWED_COOKIE_KEY)
      const expires = this.$util.dayjs().add(RECENTLY_VIEWED_SERVICES_EXPIRE_DAY, 'days').toDate()
      const cookieOption = {
        path: '/',
        expires
      }

      if (!cookieValue) {
        this.$cookies.set(RECENTLY_VIEWED_COOKIE_KEY, `0|${serviceId}`, cookieOption)
        return
      }
      const histories = cookieValue.split(',').map(history => {
        const reg = new RegExp('\\|(.+?)$')
        if (history.match(reg)) {
          return history.match(reg)[1]
        }
      })

      histories.unshift(String(serviceId))

      const newCookieValue = histories
        .filter((x, i, self) => {
          return self.indexOf(x) === i
        })
        .map((val, index) => {
          return `${index}|${val}`
        })
        .slice(0, RECENTLY_VIEWED_SERVICES_MAX)
        .join(',')

      this.$cookies.set(RECENTLY_VIEWED_COOKIE_KEY, newCookieValue, cookieOption)
    },
    // トップレスポンシブより先行してリリースしデータ移行をする（移行期間はRECENTLY_VIEWED_SERVICES_EXPIRE_DAY必要）
    // 移行期間中にアクセスがなければRECENTLY_VIEWED_SERVICES_EXPIRE_DAYを超えるため消える
    // 移行期間中にアクセスがあれば新Cookieに全データ移行する
    // リリース時は新Cookieのみの管理にする
    setBrowsingHistoryV2() {
      const serviceId = this.serviceDetail.serviceDetail.id
      const cookieValue = this.$cookies.get(RECENTLY_VIEWED_COOKIE_KEY)
      const expires = this.$util.dayjs().add(RECENTLY_VIEWED_SERVICES_EXPIRE_DAY, 'days').toDate()
      const cookieOption = {
        path: '/',
        expires
      }

      // 移行期間中は新Cookieを都度初期化（setで上書きしてくれると思うがブラウザ毎の挙動がわからないため）
      this.$cookies.remove(RECENTLY_VIEWED_COOKIE_KEY_V2)

      if (!cookieValue) {
        this.$cookies.set(RECENTLY_VIEWED_COOKIE_KEY_V2, serviceId, cookieOption)
        return
      }
      const histories = cookieValue.split(',').map(history => {
        const reg = new RegExp('\\|(.+?)$')
        if (history.match(reg)) {
          return history.match(reg)[1]
        }
      })

      histories.unshift(String(serviceId))

      const newCookieValue = histories
        .filter((x, i, self) => {
          return self.indexOf(x) === i
        })
        .slice(0, RECENTLY_VIEWED_SERVICES_MAX)
        .join(',')

      this.$cookies.set(RECENTLY_VIEWED_COOKIE_KEY_V2, newCookieValue, cookieOption)
    },
    setOptions(newOptions) {
      this.options = newOptions
    },
    showServiceIntroduction() {
      this.isShowServiceIntroduction = true
    },
    setDataLayer() {
      // Penglue用にparent_category_id追加
      if (!Array.isArray(window.dataLayer)) {
        window.dataLayer = []
      }
      window.dataLayer.push({
        service_id: String(this.serviceId),
        stop_flag: this.serviceDetail.serviceDetail.stopFg ? 1 : 0,
        pagetype: 'offerdetail',
        price: this.serviceDetail.serviceDetail.priceWot,
        price_for_criteo: this.isPhoneService
          ? this.CRITEO_PHONE_PRICE_AVG
          : this.serviceDetail.serviceDetail.priceWot,
        parent_category_id: this.parentCategoryId,
        category_id: this.currentCategoryId,
        category_type_id: this.serviceDetail.serviceDetail.masterCategoryTypeId,
        service_kind: this.getServiceKind(),
        rank: this.user.level.value,
        provider_id: this.serviceDetail.serviceDetail.providerId,
        event: 'ExcludeCategory'
      })
    },
    closeServiceIntroduction() {
      this.isShowServiceIntroduction = false
    },
    onCompletedGetGlobalPopup(popupType) {
      if (!popupType) this.showUserAttributesSelectModalWrapper = true
    },
    showPurchaseSelectDrawer() {
      this.isShowPurchaseSelectDrawer = true
    }
  }
}
</script>
<style lang="scss" scoped>
.c-detailPage {
  position: relative;
  margin: 0 auto;
  padding: 0 20px;
  max-width: 1080px;

  &_mainContent {
    position: relative;

    &-loading {
      height: calc(100vh - 320px);
    }
  }

  &_loader {
    min-height: 100%;
  }
}

.c-contentHeader {
  margin: 8px auto 16px;
  padding: 0 20px;
  width: 1080px;

  ::v-deep .breadcrumb {
    margin-bottom: 16px;
  }
}

.c-notActive {
  display: flex;
  padding: 8px;
  border-radius: 4px;
  background-color: $ds2-color-red-50;
  color: $ds2-color-gray-900;
  font-size: 13px;
  line-height: 1.5;

  &_icon {
    margin: 1px 4px 0 0;
    color: $ds2-color-red;
    font-size: 16px;
  }

  & + .c-onsiteInfo {
    margin-top: 16px;
  }
}

.c-onsiteInfo {
  display: flex;
  padding: 8px;
  border-radius: 4px;
  background-color: $ds2-color-brand-secondary-75;
  color: $ds2-color-gray-900;
  font-size: 13px;
  line-height: 1.5;

  &_icon {
    margin: 1px 4px 0 0;
    color: $ds2-color-brand-secondary-700;
    font-size: 16px;
  }
}

.c-header {
  margin-top: 12px;
  margin-bottom: 20px;
}

.c-columns {
  display: flex;

  justify-content: space-between;
}

.c-share {
  &_social {
    margin-top: 24px;
  }
}

.c-blogButton {
  display: flex;
  margin: 16px 0 24px;

  justify-content: center;
}

@media (min-width: breakpoint(ServiceDetail, M) + 1) {
  .c-columns {
    width: 1040px;

    &_side {
      width: 300px;

      flex-basis: 300px;
    }

    &_main {
      width: 690px;

      flex-basis: 690px;
    }

    ::v-deep .breadcrumb {
      margin-bottom: 12px;
    }
  }

  .c-serviceDetailImagesSpText {
    display: none;
  }

  ::v-deep .c-violation {
    margin-top: 24px;
  }
}

@media (max-width: breakpoint(ServiceDetail, M)) {
  .c-detailPage {
    padding: 0;
    max-width: 100%;
    font-size: 14px;
  }

  .c-contentHeader {
    padding: 0;
    width: 100%;

    ::v-deep .breadcrumb {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      padding: 0 12px;
      ul {
        display: inline-flex;

        flex-wrap: nowrap;
      }
    }
  }

  .c-notActive {
    margin: 0 12px;
  }

  .c-onsiteInfo {
    margin: 0 12px;
  }

  .c-columns {
    flex-direction: column;
    margin-top: 0;

    &-loading {
      background-color: $color-white;
    }

    /* TODO: IE11 Edgeで効かない */
    &_side {
      /* autoprefixer: ignore next */
      display: contents;
    }

    &_main {
      /* autoprefixer: ignore next */
      display: contents;
    }

    ::v-deep .breadcrumb {
      margin-bottom: 16px;
      padding: 0 12px;

      order: 10;
    }

    .c-header {
      margin-top: 0;
      margin-bottom: 0;
      padding: 0 12px;

      order: 20;
    }

    ::v-deep .c-serviceDetailPurchase {
      order: 30;
    }

    .c-serviceDetailImagesSpText {
      order: 40;
    }

    ::v-deep .c-serviceDetailProvider {
      order: 50;
    }

    ::v-deep .c-serviceDetailContents {
      order: 60;
    }
  }

  .c-serviceDetailImagesSpText {
    margin-top: 12px;
    height: 228px;
  }
}
</style>
