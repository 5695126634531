<template>
  <div
    @click.stop.prevent
    class="c-buttonPurchase"
  >
    <!-- 自分のサービスのとき表示 -->
    <div
      v-if="isMyService"
      class="c-buttonPurchase_button"
    >
      <CButtonV2
        width="100%"
        color="primary"
        :size="buttonSize"
        @click="clickEditButton()"
      >
        <CoconalaIcon name="pencil-square-o" />
        サービスを編集する
      </CButtonV2>
    </div>
    <!-- 即時+（事前・事後予約）のとき表示 -->
    <div
      v-else-if="isWaitingReservable"
      :class="{ 'c-buttonPurchase-waitingReservable': isWaitingReservable }"
    >
      <div class="c-buttonPurchase_button gtm-cart">
        <c-button-v2
          :color="purchaseButtonColor"
          :size="buttonSize"
          :disabled="purchaseButtonIsDisable"
          @click="clickPurchaseButton($event, true)"
          width="100%"
        >
          <coconala-icon
            :name="purchaseButtonIcon"
            v-if="isPC"
          />
          <DTranslate :text="isPC ? purchaseButtonStatus.text : purchaseButtonStatus.textSp" />
        </c-button-v2>
      </div>

      <div class="c-buttonPurchase_button c-buttonPurchase_waitingReservable">
        <c-button-v2
          :color="purchaseButtonStatus.colorReservable"
          :size="buttonSize"
          @click="clickPurchaseButton($event)"
          width="100%"
        >
          <coconala-icon
            :name="purchaseButtonStatus.iconReservable"
            v-if="isPC"
          />
          <DTranslate
            :text="
              isPC ? purchaseButtonStatus.textReservable : purchaseButtonStatus.textReservableSp
            "
          />
        </c-button-v2>
      </div>
    </div>
    <div
      v-else
      class="c-buttonPurchase_button gtm-cart"
    >
      <c-button-v2
        :color="purchaseButtonColor"
        :size="buttonSize"
        :disabled="purchaseButtonIsDisable"
        @click="clickPurchaseButton($event)"
        width="100%"
      >
        <coconala-icon :name="purchaseButtonStatus.icon" />
        <DTranslate :text="purchaseButtonText" />
      </c-button-v2>
    </div>

    <!-- 多重購入警告モーダル -->
    <c-modal
      :title="$t('components.button_purchase.proceed_to_purchase')"
      class="c-inProgressServiceModal"
      :active="showInProgressServiceModal"
      @close="closeInProgressServiceModal"
    >
      <div class="c-inProgressServiceModal_inner">
        <!-- prettier-ignore -->
        <div class="c-inProgressServiceModal_text">
          {{ $t('components.button_purchase.already') }}<br />
          {{ $t('components.button_purchase.message') }}
        </div>
        <div class="c-inProgressServiceModal_buttons">
          <c-button
            color="thirdly"
            size="medium"
            @click="closeInProgressServiceModal"
          >
            {{ $t('components.button_purchase.back') }}
          </c-button>
          <c-button
            color="primary-green"
            size="medium"
            class="gtm-cart"
            @click="clickDuplicatePurchaseConfirmedBotton($event)"
          >
            {{ $t('components.button_purchase.forward') }}
          </c-button>
        </div>
        <div
          class="c-inProgressServiceModal_toTalkroom"
          v-if="serviceDetail.buyer.openServiceTalkroomId"
        >
          <!-- prettier-ignore -->
          <a
            href="javascript:;"
            @click="clickTalkrooms(serviceDetail.buyer.openServiceTalkroomId)"
          >{{ $t('components.button_purchase.talkroom') }}</a>
        </div>
      </div>
    </c-modal>
  </div>
</template>
<script>
import DTranslate from '~/components/atoms/DS2/DTranslate/DTranslate.vue'
// 即時とともに使われるステータスを管理
const WITH_WAITING_STATUSES = ['waiting_and_can_advance_reservation', 'waiting_reservable']
// 定期購入可能なステータスを管理
const CAN_SUBSCRIBE_STATUS = ['available', 'not_video_supported_device', 'can_advance_reservation']

const PURCHASE_BUTTON_STATUS_LIST = {
  incompatible: {
    color: 'primary',
    text: '対応不可',
    icon: 'slash-circle-o',
    isDisable: true
  },
  waiting: {
    color: 'primary-blue',
    text: '今すぐ電話する',
    icon: 'phone',
    isDisable: false
  },
  // 即時・事前予約
  waiting_and_can_advance_reservation: {
    color: 'primary-blue',
    colorReservable: 'primary',
    text: '今すぐ電話する',
    textReservable: '予約を申し込む',
    textSp: '今すぐ電話',
    textReservableSp: '予約申込み',
    icon: 'phone',
    iconReservable: 'calendar',
    isDisable: false
  },
  // 即時・事後予約
  waiting_reservable: {
    color: 'primary-blue',
    colorReservable: 'primary',
    text: '今すぐ電話する',
    textReservable: '電話サービスを購入',
    textSp: '今すぐ電話',
    textReservableSp: '購入に進む',
    icon: 'phone',
    iconReservable: 'calendar',
    isDisable: false
  },
  waiting_only: {
    color: 'primary',
    text: '待機中のみ受付可',
    icon: 'slash-circle-o',
    isDisable: true
  },
  // 事前予約のみ（ビデチャ・電話ともに）
  can_advance_reservation: {
    color: 'primary',
    text: '予約を申し込む',
    textSp: '予約を申し込む',
    icon: 'calendar',
    isDisable: false
  },
  // 事後予約のみ（電話のみ）
  reservable_only: {
    color: 'primary',
    text: '電話サービスを購入',
    textSp: '電話サービスを購入',
    icon: 'cart',
    isDisable: false
  },
  stop: {
    color: 'primary',
    text: '受付休止中',
    icon: 'slash-circle-o',
    isDisable: true
  },
  busy: {
    color: 'primary',
    text: '少々お待ち下さい',
    icon: 'slash-circle-o',
    isDisable: true
  },
  full_correspondence: {
    color: 'primary',
    text: '満枠対応中',
    icon: 'slash-circle-o',
    isDisable: true
  },
  // 事後予約のみ（ビデチャのみ）
  available: {
    color: 'primary',
    text: '購入画面に進む',
    icon: 'cart',
    isDisable: false
  },
  archive: {
    color: 'primary',
    text: '受付終了',
    icon: '',
    isDisable: true
  },
  not_video_supported_device: {
    color: 'primary',
    text: '購入画面に進む',
    icon: 'cart',
    isDisable: true
  },
  subscribe: {
    color: 'primary',
    text: '購入プランを選択',
    icon: 'cart',
    isDisable: false
  }
}

// vuex
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ButtonPurchase',
  components: { DTranslate },
  props: {
    buttonSize: {
      type: String,
      default: 'l'
    },
    serviceDetail: {
      type: Object,
      required: true
    },
    options: {
      type: Array,
      default: () => []
    },
    isMyService: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      purchaseParams: {},
      showInProgressServiceModal: false,
      canGoToAdvanceReservation: false, // 遷移先が日時選択画面か判定するフラグ
      purchaseButtonStatusList: PURCHASE_BUTTON_STATUS_LIST
    }
  },
  computed: {
    ...mapGetters('auth', ['isLoggedIn']),
    ...mapGetters('ui', ['isPC', 'isTB', 'isSP']),
    ...mapGetters('pages/services/serviceDetail', ['canAdvanceReservation']),
    // 2つ同時にボタンを表示するかどうか判定
    // 現状だと即時＋（事前or事後予約）の組み合わせ
    isWaitingReservable() {
      return WITH_WAITING_STATUSES.includes(this.serviceDetail.serviceDetail.purchaseStatus)
    },
    purchaseButtonStatus() {
      let status = this.serviceDetail.serviceDetail.purchaseStatus

      if (!status) {
        status = 'stop'
      }
      if (this.isSubscribe && CAN_SUBSCRIBE_STATUS.includes(status)) {
        return this.purchaseButtonStatusList['subscribe']
      }
      return this.purchaseButtonStatusList[status]
    },
    purchaseButtonColor() {
      return this.purchaseButtonStatus.color
    },
    purchaseButtonText() {
      if ((this.isSP || this.isTB) && this.purchaseButtonStatus.textSp) {
        return this.purchaseButtonStatus.textSp
      }
      return this.purchaseButtonStatus.text
    },
    purchaseButtonIcon() {
      return this.purchaseButtonStatus.icon
    },
    purchaseButtonIsDisable() {
      return this.purchaseButtonStatus.isDisable
    },
    isSubscribe() {
      return this.serviceDetail.serviceDetail.canSubscribe
    }
  },
  methods: {
    ...mapActions('ui', ['showLoginModal']),
    clickPurchaseButton(e, instant = false) {
      // オーダーのパラメータを作成
      const params = {
        service_id: this.serviceDetail.serviceDetail.id,
        option_ids: this.options
      }

      if (this.serviceDetail.serviceDetail.purchaseStatus === 'waiting' || instant) {
        params['instant'] = 1
      }

      this.purchaseParams = params

      // 非ログインの場合はログインモーダルを表示
      if (!this.isLoggedIn) {
        e.preventDefault()
        this.showLoginModal()
        return
      }

      if (this.checkCanGoToAdvanceReservationPage(instant)) {
        this.canGoToAdvanceReservation = true
      }

      this.canGoToAdvanceReservation = this.checkCanGoToAdvanceReservationPage(instant)

      // 購入済みサービスの場合は多重購入防止モーダルを表示
      if (this.serviceDetail.buyer.isInProgressService) {
        this.showInProgressServiceModal = true
        return
      }

      // 定期購入ボタン押下時、サブスクカードを表示する
      if (this.isSubscribe) {
        this.$emit('show-purchase-select-drawer')
        return
      }

      // 「予約を申し込む」ボタン（事前予約時に表示される）押下時、日時選択画面へ遷移させる
      if (this.canGoToAdvanceReservation) {
        location.href = this.$coconala.serviceReservationUrl(this.purchaseParams)
        return
      }

      location.href = this.$coconala.servicePurchaseUrl(this.purchaseParams)
    },
    clickDuplicatePurchaseConfirmedBotton() {
      if (this.isSubscribe) {
        this.closeInProgressServiceModal()
        this.$emit('show-purchase-select-drawer')
        return
      }
      // 日時選択画面
      location.href = this.canGoToAdvanceReservation
        ? this.$coconala.serviceReservationUrl(this.purchaseParams)
        : this.$coconala.servicePurchaseUrl(this.purchaseParams)
    },
    closeInProgressServiceModal() {
      this.showInProgressServiceModal = false
    },
    clickTalkrooms(id) {
      location.href = `/talkrooms/${id}`
    },
    clickEditButton() {
      location.href = `/mypage/services/${this.serviceDetail.serviceDetail.id}`
    },
    // 事前予約画面に遷移できるか判定
    checkCanGoToAdvanceReservationPage(instant = false) {
      // waiting_and_can_advance_reservationのときに即時と事前予約の判定ができないのでinstantをみる（falseのとき事前予約）
      return this.canAdvanceReservation && !instant
    }
  }
}
</script>
<style lang="scss" scoped>
.c-buttonPurchase {
  &_waitingReservable {
    margin-top: 8px;
  }
}
.c-inProgressServiceModal {
  &_inner {
    margin: 0 auto;
    padding: 24px;
    text-align: center;
  }

  &_text {
    display: inline-block;
    margin-bottom: 24px;
    max-width: 320px;
    text-align: left;
  }

  &_buttons {
    margin-bottom: 12px;

    ::v-deep .button {
      &.is-medium {
        @include link-effect;

        width: 120px;

        & + .is-medium {
          margin-left: 20px;
        }

        span {
          font-size: 13px;
        }
      }
    }
  }
}

::v-deep .c-modal {
  &_inner {
    margin: 0 auto;
    max-width: 95%;
    width: 600px;
  }
}

@media (max-width: breakpoint(ServiceDetail, M)) {
  .c-buttonPurchase {
    &-waitingReservable {
      display: flex;

      justify-content: space-between;
    }

    &_button {
      flex: 1 1 auto;
    }

    &_waitingReservable {
      margin-top: 0;
      margin-left: 8px;
    }
  }

  ::v-deep .c-buttonV2 {
    &.is-m {
      padding: 0 4px;
    }
  }

  .c-inProgressServiceModal {
    &_inner {
      padding: 24px 0;
    }
  }

  ::v-deep .c-modal {
    &_inner {
      width: auto;
    }
  }
}
</style>
